import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import ContactWidget from '../components/ContactWidget'
import Layout from '../components/Layout'

export const ContactPageTemplate = ({ title }) => {
  return (
    <Layout>
      <section className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title">{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="section">
            <ContactWidget />
          </div>
        </div>
      </section>
    </Layout>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return <ContactPageTemplate title={frontmatter.title} />
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
    }
  }
`
