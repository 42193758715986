import React from 'react'
import './styles.sass'

export default () => (
  <div className="content contact-widget">
    <form
      className="content contact-widget"
      data-netlify="true"
      method="post"
      name="contact"
      netlify-honeypot="bot-field"
    >
      <input
        type="hidden"
        name="form-name"
        style={{ display: 'none' }}
        value="contact"
      />

      <div className="hidden" style={{ display: 'none' }}>
        <label>
          Don't fill this out if you're human: <input name="bot-field" />
        </label>
      </div>

      <div className="field">
        <label className="label">Your Name</label>
        <div className="control">
          <input className="input" name="name" type="text" />
        </div>
      </div>

      <div className="field">
        <label className="label">Your Email</label>
        <div className="control has-icons-right">
          <input className="input" name="email" type="email" />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope" />
          </span>
          <span className="icon is-small is-right">
            <i className="fas fa-exclamation-triangle" />
          </span>
        </div>
      </div>

      <div className="field">
        <label className="label">Topic</label>
        <div className="control">
          <div className="select">
            <select>
              <option>General</option>
              <option>Retreats</option>
              <option>Spiritual Direction</option>
              <option>Workshops</option>
            </select>
          </div>
        </div>
      </div>

      <div className="field">
        <div className="control">
          <label className="label">
            Message
            <textarea className="textarea" name="message" />
          </label>
        </div>
      </div>

      <div className="field is-grouped">
        <div className="control">
          <button className="button is-link">Send</button>
        </div>
      </div>
    </form>
  </div>
)
